import { useState } from "react";

function useFetch() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  const execute = async (
    url,
    method = "GET",
    body = null,
    headers = {},
    isFormData = false,
    retry = true
  ) => {
    setLoading(true);
    setError(null);
    setData(null);
    setStatus(null);

    try {
      const options = {
        method,
        headers: isFormData
          ? headers
          : { "Content-Type": "application/json", ...headers },
        body: isFormData ? body : body ? JSON.stringify(body) : null,
        credentials: "include",
      };

      const response = await fetch(url, options);
      setStatus(response.status);
      if (!response.ok) {
        if (response.status === 401 && retry && !url.endsWith("/auth/login")) {
          // Intenta refrescar el token solo si no es una petición de login
          const refreshResponse = await fetch('/auth/refresh-token', {
            method: 'POST',
            credentials: 'include'
          });

          if (refreshResponse.ok) {
            // Token refrescado con éxito, reintenta la solicitud original
            return execute(url, method, body, headers, isFormData, false);
          } else {
            // Si el refresco del token falla, redirigir al usuario a la página de login
            window.location.href = '/login';
            throw new Error('Token de actualización no válido, inicia sesión nuevamente');
          }
        }

        const errorData = await response.json();
        const errorObject = {
          message: errorData.message || "Error en el servidor",
          status: response.status,
        };
        setError(errorObject);
        throw errorObject;
      }

      const responseText = await response.text();
      const contentType = response.headers.get('content-type');
      let responseData;
      if (contentType && contentType.includes('application/json')) {
        responseData = JSON.parse(responseText);
        setData(responseData);
      } else {
        responseData = responseText;
        setData(responseText);
      }

      setLoading(false);
      return { status: response.status, ok: response.ok, data: responseData };
    } catch (err) {
      console.error(`Error fetching data from ${url}:`, err);
      setError(err);
      setLoading(false);
      throw err;
    }
  };

  return { loading, data, error, execute };
}

export default useFetch;

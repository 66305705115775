/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SideBar from "../components/SideBar";
import CompanySelector from "../components/Portals/SeleccionarEmpresas";
import mainStyle from "../styles/mainComponents.module.css";
import styles from "../styles/equipo.module.css";
import useFetch from "../hooks/useFetch";
import { CompanyContext } from '../features/CompanyContext';
import ButtonPrincipal from "../components/ButtonPrincipal";
import ImportarEmpleadosPopup from "../components/Portals/ImportarEmpleadosPopup";
import AñadirUsuarioPopup from "../components/Portals/AñadirUsuarioPopup";
import EliminarUsuarioPopup from "../components/Portals/EliminarUsuarioPopup";
import EditarUsuarioPopup from "../components/Portals/EditarUsuarioPopup";
import EliminarEquipoPopup from "../components/Portals/EliminarEquipoPopup";
import { useTranslation } from "react-i18next";
import { ErrorPopup, SuccessPopup } from "../components/Popup";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

function Equipo() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedCompany, selectedCompanyId, setSelectedCompany, setSelectedCompanyId } = useContext(CompanyContext);
  const [companies, setCompanies] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, execute } = useFetch();
  const { data: dataPost, execute: executePost, loading: loadingPost } = useFetch();
  const { execute: executeImportPost } = useFetch();
  const { execute: executeAddUsuarioPost } = useFetch();
  const { execute: executeDeleteUsuarioPost } = useFetch();
  const { execute: executeUpdateUsuarioPost } = useFetch();
  const { execute: executeSendEmail } = useFetch();
  const { execute: executeDelete } = useFetch();
  const executeRef = useRef(execute);
  const executePostRef = useRef(executePost);
  const executeAddUsuarioPostRef = useRef(executeAddUsuarioPost);
  const executeDeleteUsuarioPostRef = useRef(executeDeleteUsuarioPost);
  const executeUpdateUsuarioPostRef = useRef(executeUpdateUsuarioPost);
  const executeDeleteRef = useRef(executeDelete);
  const executeSendEmailRef = useRef(executeSendEmail);
  const { id } = useParams();
  const [empleados, setEmpleados] = useState([]);
  const [nombreEquipo, setNombreEquipo] = useState('');
  const [isImportPopupOpen, setIsImportPopupOpen] = useState(false);
  const [isAddUserPopupOpen, setIsAddUserPopupOpen] = useState(false);
  const [isDeleteUserPopupOpen, setIsDeleteUserPopupOpen] = useState(false);
  const [isEditUserPopupOpen, setIsEditUserPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToSendEmail, setUserToSendEmail] = useState(null);
  const [succesCreatedUser, setSuccesCreatedUser] = useState(false);
  const [errorCreatedUser, setErrorCreatedUser] = useState(false);
  const [succesDeletedUser, setSuccesDeletedUser] = useState(false);
  const [succesUpdatedUser, setSuccesUpdatedUser] = useState(false);
  const [succesImpoertedUsers, setSuccesImportedUsers] = useState(false);
  const [errorCreatedUserByOtherCompany, setErrorCreatedUserByOtherCompany] = useState(false);
  const [errorImportingUsers, setErrorImportingUsers] = useState(false);
  const [succesSendEmail, setSuccesSendEmail] = useState(false);
  const [errorSendEmail, setErrorSendEmail] = useState(false);
  const [nombreEmpresaEquipo, setNombreEmpresaEquipo] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const fetchApiData = (url, executeRef) => {
    const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
    executeRef.current(`${apiUrl}${url}`, "GET");
  };

  const fetchApiDataPost = async (url, executeRef, body) => {
    const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
    await executeRef.current(`${apiUrl}${url}`, "POST", body);
  };
  
  const fetchApiDataDelete = async (url, executeRef, body) => {
      const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
      await executeRef.current(`${apiUrl}${url}`, "DELETE", body);
  };

  useEffect(() => {
    executeRef.current = execute;
    executePostRef.current = executePost;
  }, [execute, executePost]);

  useEffect(() => {
    fetchApiData("/gestionCoach/getEmpresas", executeRef);
    if (id) {
      fetchApiDataPost("/gestionCoach/getEmpleados", executePostRef, {
        id_equipo: id,
        id_empresa: selectedCompanyId,
      });
    }
  }, [id, selectedCompanyId]);

  useEffect(() => {
    if (data) {
      setCompanies(data);
    }
    if (dataPost) {
      setEmpleados(dataPost.empleados);
      setNombreEquipo(dataPost.equipo);
      setNombreEmpresaEquipo(dataPost.empresa);
      setSelectedCompany(dataPost.empresa);
      setSelectedCompanyId(dataPost.id_empresa);
    }
  }, [data, dataPost]);

  useEffect(() => {
    if (!selectedCompany) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [selectedCompany]);

  const handleImport = async (file) => {
    setSuccesImportedUsers(false);
    setErrorImportingUsers(false);
    try {
      const formData = new FormData();
        formData.append('file', file);
        formData.append('id_empresa', selectedCompanyId);
        formData.append('id_equipo', id);
      const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
      await executeImportPost(`${apiUrl}/gestionCoach/cargar-empleados-excel`, "POST", formData, {},true);
      setSuccesImportedUsers(true);
      setTimeout(() => {
        fetchApiDataPost("/gestionCoach/getEmpleados", executePostRef, {
          id_equipo: id,
          id_empresa: selectedCompanyId,
        });
      }, 500);
    }
    catch (error) {
      console.error("Error posting to API:", error);
      setErrorImportingUsers(true);
    }
  };

  const handleAddUser = async (user) => {
    setErrorCreatedUser(false);
    setSuccesCreatedUser(false);    
    setErrorCreatedUserByOtherCompany(false);
    try {
      await fetchApiDataPost("/gestionCoach/crearEmpleado", executeAddUsuarioPostRef, {
        id_empresa: selectedCompanyId,
        id_equipo: id,
        nombre: user.nombre,
        apellido: user.apellido,
        email: user.email,
      });
      setSuccesCreatedUser(true);
      setTimeout(() => {
        fetchApiDataPost("/gestionCoach/getEmpleados", executePostRef, {
          id_equipo: id,
          id_empresa: selectedCompanyId,
        });
      }, 500);
    } catch (error) {
      if (error.status === 405) {
        setErrorCreatedUserByOtherCompany(true);
        return;
      }
      console.error("Error posting to API:", error.status);
      setErrorCreatedUser(true);
    }
  };
  
  
  const handleDeleteUser = async () => {
    setSuccesDeletedUser(false);
    try {
      await fetchApiDataDelete("/gestionCoach/deleteEmpleado", executeDeleteUsuarioPostRef, {
        id_empleado: userToDelete.id_empleado,
        id_equipo: id,
      });
      setSuccesDeletedUser(true); 
      setTimeout(() => {
        fetchApiDataPost("/gestionCoach/getEmpleados", executePostRef, {
          id_equipo: id,
          id_empresa: selectedCompanyId,
        });
      }, 500);
    } catch (error) {
      console.error("Error posting to API:", error);
    }
  };

  useEffect(() => {
    if (userToSendEmail) {
      handleSendEmail();
    }
  }, [userToSendEmail]);
  
  const handleSendEmail = async () => {
    setSuccesSendEmail(false);
    setErrorSendEmail(false);
    try {
      await fetchApiDataPost("/gestionEncuestas/enviarEncuestaEmpleado", executeSendEmailRef, {
        id_empleado: userToSendEmail.id_empleado,
        id_equipo: id,
      });
      
      setSuccesSendEmail(true);
    } catch (error) {
      console.error("Error posting to API:", error);
      setErrorSendEmail(true);
    }
  };  

  const handleUpdateUser = async (user) => {
    setSuccesUpdatedUser(false);
    try{
    await fetchApiDataPost("/gestionCoach/updateEmpleado", executeUpdateUsuarioPostRef, {
      id_empleado: user.id_empleado,
      nombre: user.nombre,
      apellido: user.apellido,
      email: user.email,
      rol: user.rol,
      id_equipo: id,
    });
    setSuccesUpdatedUser(true);
    setTimeout(() => {
      fetchApiDataPost("/gestionCoach/getEmpleados", executePostRef, {
        id_equipo: id,
        id_empresa: selectedCompanyId,
      });
    }, 500);
  }
  catch (error) {
    console.error("Error posting to API:", error);
  }
  };

  const handleDelete = async () => {
    try {
      await fetchApiDataDelete("/gestionCoach/deleteEquipo", executeDeleteRef, {
        id_equipo: id,
      });
      setTimeout(() => {
        fetchApiData("/gestionCoach/getEmpresas", executeRef);
        setSelectedCompany('');
        setSelectedCompanyId('');
        navigate('/empresas');
      }, 500);
    } catch (error) {
      console.error("Error posting to API:", error);
    }
  };

  return (
    <>
    <div className={mainStyle.main}>
      <SideBar selectedCompany={selectedCompany} />
      {succesCreatedUser && <SuccessPopup message={t("userCreated")}/>}
      {errorCreatedUser && <ErrorPopup message={t("errorCreatingUser")}/>}
      {succesDeletedUser && <SuccessPopup message={t("userDeleted")}/>}
      {succesUpdatedUser && <SuccessPopup message={t("userUpdated")}/>}
      {succesImpoertedUsers && <SuccessPopup message={t("usersImported")}/>}
      {errorImportingUsers && <ErrorPopup message={t("errorImportingUsers")}/>}
      {succesSendEmail && <SuccessPopup message={t("emailSent")}/>}
      {errorSendEmail && <ErrorPopup message={t("errorSendingEmail")}/>}
      {errorCreatedUserByOtherCompany && <ErrorPopup message={t("errorCreatingUserByOtherCompany")}/>}
      {loadingPost && <Loader />}
      <div className={mainStyle.central}>
        <div className={styles.header}>
          <svg className={styles.svgDelete} onClick={() => window.history.back()} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"/></svg>
          <p className={styles.nombreempresaEquipo}>{nombreEmpresaEquipo} <span className={styles.guionrosa}>-</span> {nombreEquipo}</p>
          <svg className={styles.svgDelete} onClick={() => setIsDeletePopupOpen(true)} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
        </div>
        <div className={styles.divCentral}>
          <div className={styles.header}>
          <input
            type="text"
            placeholder={t("search")}
            className={styles.searchInput}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          />
            <div className={styles.buttonsimport}>
              <ButtonPrincipal className={styles.button} onClick={() => setIsImportPopupOpen(true)}>
              {t("import")}
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>
              </ButtonPrincipal>
              <ButtonPrincipal className={styles.button} onClick={() => setIsAddUserPopupOpen(true)}>
              {t("addUser")}
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
              </ButtonPrincipal>
            </div>
          </div>
          <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th></th>
                <th>{t("name")}</th>
                <th>{t("surname")}</th>
                <th>{t("mail")}</th>
                {empleados && empleados.length > 0 && empleados.some(emp => emp.preguntasRestantes !== -1) && (
                  <th>{t("OutstandingQuestions")}</th>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {empleados && empleados.length > 0 ? (
                empleados
                  .filter((employee) =>
                    employee.nombre.toLowerCase().includes(searchTerm) ||
                    employee.apellido.toLowerCase().includes(searchTerm) ||
                    employee.email.toLowerCase().includes(searchTerm)
                  )
                  .map((employee) => (
                    <tr key={employee.id_empleado}>
                      <td>
                        {(employee.rol === 'responsable' || employee.rol === 'ambos') && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16px"
                            viewBox="0 0 24 24"
                            width="16px"
                            style={{ verticalAlign: 'middle' }}
                            className={styles.responsibleIcon}
                          >
                            <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5C14 14.17 9.33 13 8 13zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 2.03 1.97 3.45V19h4v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
                          </svg>
                        )}
                      </td>
                      <td>{employee.nombre}</td>
                      <td>{employee.apellido}</td>
                      <td>{employee.email}</td>
                      {employee.preguntasRestantes !== -1 && (
                        <td>
                          <div className={styles.preguntasRestantesContainer}>
                            <span
                              className={employee.preguntasRestantes === 0 ? styles.greenCircle : styles.redCircle}
                            ></span>
                            <span>{employee.preguntasRestantes}</span>
                          </div>
                        </td>
                      )}
                      <td>
                        <div className={styles.actionIcons}>
                          <button onClick={() => { setUserToDelete(employee); setIsDeleteUserPopupOpen(true); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                          </button>
                          <button onClick={() => { setUserToEdit(employee); setIsEditUserPopupOpen(true); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/></svg>
                          </button>
                          {employee.preguntasRestantes !== 0 && employee.preguntasRestantes !== -1 && (
                            <button onClick={() => setUserToSendEmail(employee)}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="22px" fill="#5f6368">
                                <path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z"/>
                              </svg>                        
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="4">{t("noEmpleadosDisponibles")}</td>
                </tr>
              )}
            </tbody>
          </table>
          </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CompanySelector
        setSelectedCompany={setSelectedCompany}
        setSelectedCompanyId={setSelectedCompanyId}
        companies={companies}
      />
      )}
      {isImportPopupOpen && (
        <ImportarEmpleadosPopup
          onClose={() => setIsImportPopupOpen(false)}
          onImport={handleImport}
        />
      )}
      {isAddUserPopupOpen && (
        <AñadirUsuarioPopup
          onClose={() => setIsAddUserPopupOpen(false)}
          onCreate={handleAddUser}
        />
      )}
      {isDeleteUserPopupOpen && userToDelete && (
        <EliminarUsuarioPopup
          onClose={() => setIsDeleteUserPopupOpen(false)}
          onDelete={handleDeleteUser}
          userName={`${userToDelete.nombre} ${userToDelete.apellido}`}
        />
      )}
      {isEditUserPopupOpen && userToEdit && (
        <EditarUsuarioPopup
          onClose={() => setIsEditUserPopupOpen(false)}
          onUpdate={handleUpdateUser}
          user={userToEdit}
        />
      )}
      {isDeletePopupOpen && (
        <EliminarEquipoPopup
          onClose={() => setIsDeletePopupOpen(false)}
          onDelete={handleDelete}
          companyName={nombreEquipo}
        />
      )}
    </>
  );
}

export default Equipo;

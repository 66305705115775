import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import styles from "../../styles/miperfil.module.css";
import ButtonPrimario from "../ButtonPrincipal";
import ButtonSecundario from "../ButtonSecundario";
import { useSelector } from "react-redux";
import Select from "react-select";
import useFetch from "../../hooks/useFetch";
import { SuccessPopup } from "../Popup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateProfileImage } from "../../features/userSlice";
import getCroppedImg from "../../features/getCroppedImg";
import Modal from "react-modal";
import Cropper from "react-easy-crop";
import { Link } from "react-router-dom";
import { logout } from "../../features/userSlice";
import { useNavigate } from "react-router-dom";

const MiPerfil = ({ cerrarPopup }) => {
  const { t, i18n } = useTranslation();
  const popupRef = useRef(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    upperCase: false,
    lowerCase: false,
    symbol: false,
    match: false,
  });
  const allValidationsPassed =
    Object.values(passwordValidations).every(Boolean);
  const { data, execute } = useFetch();
  const { execute: execute2 } = useFetch();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.info);
  const [imageSrc, setImageSrc] = useState(userInfo.imageURL);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [originalImageSrc, setOriginalImageSrc] = useState(userInfo.imageURL);

  const handleCloseModal = () => {
    setImageSrc(originalImageSrc); // Restaura la imagen original
    setIsModalOpen(false); // Cierra el modal
  };

  
  const navigate = useNavigate();

  const cerrarMobile = () => {
    cerrarPopup();
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isModalOpen) {
        return;
      }

      if (popupRef.current && !popupRef.current.contains(event.target)) {
        cerrarPopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cerrarPopup, isModalOpen]);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    setPasswordValidations({
      length: value.length >= 8 && value.length <= 15,
      upperCase: /[A-Z]/.test(value),
      lowerCase: /[a-z]/.test(value),
      symbol: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      match: value === confirmPassword,
    });
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setPasswordValidations((prev) => ({ ...prev, match: password === value }));
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
  
    if (isSubmitting) return;
  
    setShowSuccessPopup(false);
  
    if (allValidationsPassed && password && password === confirmPassword) {
      setIsSubmitting(true);
      const apiUrl =
        process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
      try {
        const response = await execute2(
          `${apiUrl}/auth/change-password`,
          "PUT",
          { password },
          true,
        );
        if (response && response.status === 200) {
          setShowSuccessPopup(true);
          setTimeout(() => {
            cerrarPopup(); // Cierra el portal después de 2 segundos
          }, 2000);
        }
      } catch (error) {
        console.error(error);
      }
      setIsSubmitting(false);
    }
  };
  
  const cambiarIdioma = (opcionSeleccionada) => {
    i18n.changeLanguage(opcionSeleccionada.value);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      handleUpload(croppedImage);
      setIsModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpload = async (imageToUpload) => {
    const formData = new FormData();
    formData.append("image", imageToUpload);
    const apiUrl =
      process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
    await execute(`${apiUrl}/auth/updateImageProfile`, "PUT", formData, {},true);
  };

  useEffect(() => {
    if (data) {
      const newImageUrl = data.imageURL;
      setImageSrc(newImageUrl);
      dispatch(updateProfileImage(newImageUrl));
    }
  }, [data, dispatch]);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setOriginalImageSrc(imageSrc); // Guarda la imagen original
        setImageSrc(reader.result); // Muestra la imagen seleccionada
        setIsModalOpen(true);
      };
    }
  };
  

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const opcionesIdioma = [
    {
      value: "es",
      label: (
        <div>
          <img
            src="/img/banderas/es.png"
            alt="ES"
            style={{ width: "20px", height: "15px", marginRight: "10px" }}
          />{" "}
          Español
        </div>
      ),
    },
    {
      value: "en",
      label: (
        <div>
          <img
            src="/img/banderas/en.png"
            alt="EN"
            style={{ width: "20px", height: "15px", marginRight: "10px" }}
          />{" "}
          English
        </div>
      ),
    },
  ];

  async function handleLogout() {
    const response = await execute(
      `${process.env.REACT_APP_API_URL}/auth/logout`,
      "GET",
    );
    if (response && response.status === 200) {
      dispatch(logout());
      navigate("/login");
    } else {
      console.log("Error al cerrar sesión:");
    }
  }

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={(e) => e.stopPropagation()}>
      {showSuccessPopup && <SuccessPopup message={t("successPassword")} />}
      <div className={styles.popup} ref={popupRef} style={{ zIndex: 100000 }}>
        <div className={styles.contentPopup}>
        <div className={styles.botonCerrar} onClick={cerrarMobile}>
          <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30" > <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none" /></svg>
        </div>
          <div className={styles.changeImage}>
            <img
              className={styles.imgProfile}
              src={imageSrc || userInfo?.img_profile}
              alt="Imagen de perfil"
            />
            <ButtonSecundario onClick={handleButtonClick}>{t("changeImage")}</ButtonSecundario>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              className={styles.modal}
              overlayClassName={styles.overlay}
            >
          <button className={styles.buttonModal} onClick={handleCloseModal}>
            {t("buttons.close")}
          </button>
                        {imageSrc && (
                <div className={styles.cropContainer}>
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={4 / 4}
                    cropShape="round"
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>
              )}
              <button className={styles.buttonModal} onClick={showCroppedImage}>{t("buttons.saveCroppedImage")}</button>
            </Modal>
          </div>
          <div className={styles.changePassword}>
            <form>
              <input
                type="password"
                placeholder={t("placeholders.newPassword")}
                onChange={handlePasswordChange}
              />
              <input
                type="password"
                placeholder={t("placeholders.repeatNewPassword")}
                onChange={handleConfirmPasswordChange}
              />
              <ul className={styles.verifyInputs}>
                <li
                  style={{
                    color: passwordValidations.length ? "green" : "white",
                  }}
                >
                  {t("passwordValidation.charLength")}
                </li>
                <li
                  style={{
                    color: passwordValidations.upperCase ? "green" : "white",
                  }}
                >
                  {t("passwordValidation.upperCase")}
                </li>
                <li
                  style={{
                    color: passwordValidations.lowerCase ? "green" : "white",
                  }}
                >
                  {t("passwordValidation.lowerCase")}
                </li>
                <li
                  style={{
                    color: passwordValidations.symbol ? "green" : "white",
                  }}
                >
                  {t("passwordValidation.symbol")}
                </li>
                <li
                  style={{
                    color: passwordValidations.match ? "green" : "white",
                  }}
                >
                  {t("passwordValidation.match")}
                </li>
              </ul>
              <ButtonPrimario
                disabled={!allValidationsPassed}
                onClick={handleSubmitPassword}
              >
                {t("buttons.changePassword")}
              </ButtonPrimario>
            </form>
            <div className={styles.legalPages}>
              <a href="https://feelinback.com/politicaprivacidad">{t("termsAndConditions")}</a>
              <a href="https://feelinback.com/avisolegal">{t("legalNotice")}</a>
            </div>
          </div>
          <div className={styles.changeLanguage}>
            <Select
              options={opcionesIdioma}
              placeholder="Cambiar idioma"
              menuPlacement="top"
              onChange={cambiarIdioma}
              value={opcionesIdioma.find(
                (opcion) => opcion.value === i18n.language,
              )}
            />
          </div>
          <div className={styles.butonLogoutPopup}>
          <Link to="/login" onClick={handleLogout}>{t("sidebar-logout")}</Link>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default MiPerfil;

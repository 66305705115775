import React from "react";

import Style from "../styles/politicaprivacidad.module.css";


function PoliticaPrivacidad() {

  return (
    <div className={Style.container}>
      <header className={Style.header}>
        <h1 className={Style.title}>Política de Privacidad</h1>
        <p className={Style.date}>Última actualización: 22/11/2024</p>
      </header>
      <main className={Style.main}>
        <section className={Style.section}>
          <h2 className={Style.sectionTitle}>1. Responsable del Tratamiento de los Datos</h2>
          <p className={Style.text}>
            El responsable del tratamiento de sus datos es:
          </p>
          <p className={Style.text}>
            <strong>Instituto Imagen y Cultura S.L.</strong>
            <br />
            CIF: B67162214
            <br />
            Dirección: calle Bruc 150 3o 2a 08037 Barcelona.
            <br />
            Email: <a href="mailto:info@instituto8.org" className={Style.link}>info@instituto8.org</a>
            <br />
            Teléfono: +34 930234612
          </p>
          <p className={Style.text}>
            Como partner tecnológico, <strong>REVIVENTUM S.L.</strong> actuará como encargado del tratamiento,
            desarrollando y manteniendo las plataformas tecnológicas necesarias para ofrecer los servicios del Instituto.
          </p>
          <p className={Style.text}>
            <strong>REVIVENTUM S.L.</strong>
            <br />
            CIF: B56873532
            <br />
            Dirección: Carrer de Pallars, 193, Sant Martí, 08005 Barcelona
            <br />
            Email: <a href="mailto:info@feelinback.com" className={Style.link}>info@feelinback.com</a>
            <br />
            Teléfono: 635 887 535
          </p>
        </section>

        <section className={Style.section}>
          <h2 className={Style.sectionTitle}>2. Finalidades del Tratamiento</h2>
          <ul className={Style.list}>
            <li>Proveer los servicios contratados por los usuarios a través de las plataformas del Instituto.</li>
            <li>Analizar datos para mejorar la calidad de los servicios y la experiencia del usuario.</li>
            <li>Gestionar obligaciones legales, fiscales y administrativas.</li>
            <li>Enviar información relevante sobre nuestros servicios, siempre con su consentimiento previo.</li>
            <li>Detectar, prevenir y gestionar problemas técnicos o de seguridad en nuestras plataformas.</li>
          </ul>
          <p className={Style.text}>
            REVIVENTUM, como socio tecnológico, garantiza el cumplimiento de estas finalidades exclusivamente bajo
            las directrices del Instituto.
          </p>
        </section>

        <section className={Style.section}>
          <h2 className={Style.sectionTitle}>3. Tipos de Datos Recopilados</h2>
          <ul className={Style.list}>
            <li>
              <strong>Datos de Identificación:</strong> Nombre, apellidos, DNI/NIF, correo electrónico, número de
              teléfono, dirección postal.
            </li>
            <li>
              <strong>Datos Profesionales:</strong> Cargo, empresa y datos relacionados.
            </li>
            <li>
              <strong>Datos Técnicos:</strong> Dirección IP, cookies, datos de navegación.
            </li>
          </ul>
        </section>
      </main>
      <footer className={Style.footer}>
        <p className={Style.footerText}>
          Si tienes preguntas sobre esta política de privacidad, puedes contactar con nosotros en{' '}
          <a href="mailto:info@instituto8.org" className={Style.link}>info@instituto8.org</a>.
        </p>
      </footer>
    </div>
  );
}

export default PoliticaPrivacidad;

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "../../styles/crearEmpresaPopup.module.css";
import { useTranslation } from "react-i18next";


const CrearEmpresaPopup = ({ onClose, onCreate }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    nombre: "",
    cif: "",
    direccion: "",
    ciudad: "",
    codigo_postal: "",
    provincia: "",
    pais: "",
    logo: "",
    personalizacion: false,
  });

  const [errors, setErrors] = useState({});

  const validate = (name, value) => {
    let newErrors = { ...errors };

    // Expresiones regulares actualizadas
    const textRegex = /^[a-zA-Z0-9ñÑ\sáéíóúÁÉÍÓÚ&-.]+$/; // Permite letras, números, espacios, "&" y "-"
    const addressRegex = /^[a-zA-Z0-9ñÑ\sºª.,;:¡!¿?áéíóúÁÉÍÓÚ/-]+$/; // Permite letras, números, símbolos y espacios
    const countryRegex = /^[a-zA-ZñÑ\sáéíóúÁÉÍÓÚ]+$/; // Permite letras, espacios y acentos
    const cifRegex = /^[A-Za-z]\d{7}[A-Za-z0-9]$/; // Ajustado para CIF español válido
    const postalCodeRegex = /^\d{5}$/; // Permite solo números (ajustado para un código postal típico de España)

    switch (name) {
      case "nombre":
        if (!value || !textRegex.test(value)) {
          newErrors[name] = t("nameCompanyInvalid");
        } else {
          delete newErrors[name];
        }
        break;
      

      case "ciudad":
        if (!value || !textRegex.test(value)) {
          newErrors[name] = t("cityInvalid");
        } else {
          delete newErrors[name];
        }
        break;

      case "provincia":
        if (!value || !textRegex.test(value)) {
          newErrors[name] = t("provinceInvalid");
        } else {
          delete newErrors[name];
        }
        break;

      case "direccion":
        if (!value || !addressRegex.test(value)) {
          newErrors[name] = t("addressInvalid");
        } else {
          delete newErrors[name];
        }
        break;

      case "pais":
        if (!value || !countryRegex.test(value)) {
          newErrors[name] = t("countryInvalid");
        } else {
          delete newErrors[name];
        }
        break;

      case "cif":
        if (!value || !cifRegex.test(value)) {
          newErrors[name] = t("cifInvalid");
        } else {
          delete newErrors[name];
        }
        break;

      case "codigo_postal":
        if (!value || !postalCodeRegex.test(value)) {
          newErrors[name] = t("postalCodeInvalid");
        } else {
          delete newErrors[name];
        }
        break;

      default:
        break;
    }

    setErrors(newErrors);
  };

  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    validate(name, newValue);
  };

  const handleCreate = () => {
    // Crear un nuevo objeto con los valores "trimmed" (sin espacios al inicio y al final)
    const trimmedFormData = {
      ...formData,
      nombre: formData.nombre.trim(),
      cif: formData.cif.trim(),
      direccion: formData.direccion.trim(),
      ciudad: formData.ciudad.trim(),
      codigo_postal: formData.codigo_postal.trim(),
      provincia: formData.provincia.trim(),
      pais: formData.pais.trim(),
    };
  
    const allValid = Object.keys(trimmedFormData).every((key) => {
      validate(key, trimmedFormData[key]); // Validar los valores limpios
      return !errors[key]; // Verificar que no haya errores
    });
  
    if (allValid) {
      onCreate(trimmedFormData); // Enviar los valores limpios
      onClose();
    }
  };
  

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleCreate();
    }
  };

  // Añadir event listener para la tecla "Enter"
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]); // Dependencia para asegurarse de que el estado más reciente de formData sea accesible

  return ReactDOM.createPortal(
    <div className={styles.popupOverlay} onClick={handleClickOutside}>
      <div className={styles.popupContent}>
        <h2>{t("crearEmpresa")}</h2>
        <label className={styles.label}>
          {t("companyName")}:
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            className={`${styles.input} ${errors.nombre ? styles.inputError : ""}`}
          />
          {errors.nombre && <span className={styles.error}>{errors.nombre}</span>}
        </label>
        <label className={styles.label}>
          {t("cif")}:
          <input
            type="text"
            name="cif"
            value={formData.cif}
            onChange={handleChange}
            className={`${styles.input} ${errors.cif ? styles.inputError : ""}`}
          />
          {errors.cif && <span className={styles.error}>{errors.cif}</span>}
        </label>
        <label className={styles.label}>
          {t("address")}:
          <input
            type="text"
            name="direccion"
            value={formData.direccion}
            onChange={handleChange}
            className={`${styles.input} ${errors.direccion ? styles.inputError : ""}`}
          />
          {errors.direccion && <span className={styles.error}>{errors.direccion}</span>}
        </label>
        <div className={styles.divInputsHorizontal}>
          <label className={styles.label}>
            {t("city")}:
            <input
              type="text"
              name="ciudad"
              value={formData.ciudad}
              onChange={handleChange}
              className={`${styles.input} ${errors.ciudad ? styles.inputError : ""}`}
            />
            {errors.ciudad && <span className={styles.error}>{errors.ciudad}</span>}
          </label>
          <label className={styles.label}>
            {t("postalCode")}:
            <input
              type="text"
              name="codigo_postal"
              value={formData.codigo_postal}
              onChange={handleChange}
              className={`${styles.input} ${errors.codigo_postal ? styles.inputError : ""}`}
            />
            {errors.codigo_postal && <span className={styles.error}>{errors.codigo_postal}</span>}
          </label>
        </div>
        <div className={styles.divInputsHorizontal}>
          <label className={styles.label}>
            {t("province")}:
            <input
              type="text"
              name="provincia"
              value={formData.provincia}
              onChange={handleChange}
              className={`${styles.input} ${errors.provincia ? styles.inputError : ""}`}
            />
            {errors.provincia && <span className={styles.error}>{errors.provincia}</span>}
          </label>
          <label className={styles.label}>
            {t("country")}:
            <input
              type="text"
              name="pais"
              value={formData.pais}
              onChange={handleChange}
              className={`${styles.input} ${errors.pais ? styles.inputError : ""}`}
            />
            {errors.pais && <span className={styles.error}>{errors.pais}</span>}
          </label>
        </div>
        <div className={styles.popupActions}>
          <button onClick={onClose} className={styles.buttonCancel}>{t("cancel")}</button>
          <button onClick={handleCreate} className={styles.buttonCreate}>{t("create")}</button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default CrearEmpresaPopup;

import React, { useContext, useRef, useEffect, useState } from "react";
import SideBar from "../components/SideBar";
import mainStyle from "../styles/mainComponents.module.css";
import { CompanyContext } from "../features/CompanyContext";
import styles from "../styles/database.module.css";
import { useTranslation } from "react-i18next";
import useFetch from "../hooks/useFetch";

function Database() {
    const { selectedCompany } = useContext(CompanyContext);
    const { t } = useTranslation();
    const { data, execute } = useFetch();
    const [companies, setCompanies] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedSurveys, setSelectedSurveys] = useState([]);
    const [companyColors, setCompanyColors] = useState({});
    const executeRef = useRef(execute);

  const fetchApiData = (url, executeRef) => {
    const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
    executeRef.current(`${apiUrl}${url}`, "GET");
  };


  useEffect(() => {
    fetchApiData("/gestionCoach/getEmpresas", executeRef);
  }, []);

  useEffect(() => {
    if (data) {
      setCompanies(data);
      // Asignar colores pastel a cada empresa al azar
      const colors = {};
      data.forEach((company) => {
        colors[company.id_empresa] = getRandomPastelColor();
      });
      setCompanyColors(colors);
    }
  }, [data]);

  const pastelColors = [
    "#FFB3BA", // Rosa suave
    "#FFDFBA", // Melocotón
    "#FFFFBA", // Amarillo claro
    "#BAFFC9", // Verde menta
    "#BAE1FF", // Azul cielo
    "#D7BDE2", // Lavanda
    "#F5CBA7", // Naranja pastel
    "#AED6F1", // Azul pastel
    "#A2D9CE", // Verde agua
    "#F9E79F", // Amarillo cálido
    "#FFC0CB", // Rosa claro
    "#FFE4E1", // Rosa niebla
    "#E6E6FA", // Lavanda claro
    "#FFFACD", // Amarillo limón
    "#FAFAD2", // Amarillo luz dorada
    "#D1F2EB", // Verde agua suave
    "#D6EAF8", // Azul nieve
    "#FADBD8", // Rosa coral suave
    "#FDEBD0", // Melón pastel
    "#FAD7A0", // Durazno suave
  ];
  
  // Lista de colores disponibles
  let availableColors = [...pastelColors];
  
  // Función para asignar un color pastel sin repetir
  const getRandomPastelColor = () => {
    if (availableColors.length === 0) {
      // Reiniciar la lista de colores cuando se acaben
      availableColors = [...pastelColors];
    }
    const randomIndex = Math.floor(Math.random() * availableColors.length);
    const selectedColor = availableColors[randomIndex];
    // Eliminar el color de la lista disponible
    availableColors.splice(randomIndex, 1);
    return selectedColor;
  };
  
  const handleCompanySelect = (companyId) => {
    if (selectedCompanies.includes(companyId)) {
      setSelectedCompanies(selectedCompanies.filter((id) => id !== companyId));
      setSelectedTeams(
        selectedTeams.filter((teamId) =>
          companies
            .find((company) => company.id_empresa === companyId)
            ?.equipos.every((team) => team.id_equipo !== teamId)
        )
      );
    } else {
      setSelectedCompanies([...selectedCompanies, companyId]);
    }
    setSelectedSurveys([]);
  };

  const handleTeamSelect = (teamId) => {
    if (selectedTeams.includes(teamId)) {
      setSelectedTeams(selectedTeams.filter((id) => id !== teamId));
    } else {
      setSelectedTeams([...selectedTeams, teamId]);
    }
  };

  const handleSurveySelect = (surveyId) => {
    if (selectedSurveys.includes(surveyId)) {
      setSelectedSurveys(selectedSurveys.filter((id) => id !== surveyId));
    } else {
      setSelectedSurveys([...selectedSurveys, surveyId]);
    }
  };

    const handleExport = async () => {
        try {
          const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";

        const response = await fetch(`${apiUrl}/gestionCoach/exportData`, {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            credentials: "include", // Incluye cookies en la solicitud
            body: JSON.stringify({
            empresas: [...selectedCompanies],
            equipos: [...selectedTeams],
            encuestas: [...selectedSurveys],
            }),
        });
    
        if (!response.ok) {
            throw new Error("Error al descargar el archivo.", response);
        }
    
        // Convertir la respuesta en un blob
        const blob = await response.blob();
    
        // Crear una URL para el blob
        const url = window.URL.createObjectURL(blob);
    
        // Crear un enlace temporal para la descarga
        const a = document.createElement("a");
        a.href = url;
    
        // Obtener el nombre del archivo desde content-disposition
        const contentDisposition = response.headers.get("content-disposition");      
        
        const filename = contentDisposition ? contentDisposition.split("filename=")[1].replace(/"/g, "") : "archivo.xlsx";
    
        a.download = filename; // Nombre del archivo
        document.body.appendChild(a); // Añadir el enlace al DOM
        a.click(); // Simular clic para iniciar la descarga
        document.body.removeChild(a); // Eliminar el enlace después de usarlo
        } catch (error) {
        console.error("Error al exportar datos:", error);
        }
    };

    return (
        <div className={mainStyle.main}>
        <SideBar selectedCompany={selectedCompany} />
        <div className={mainStyle.central}>
            <h1 className={styles.tituloEmpresas}>{t("database")}</h1>
            <div className={styles.container}>
            <div className={styles.column}>
                <h2>Empresas</h2>
                {companies.length > 0 ? (
                companies.map((company) => (
                    <div
                    key={company.id_empresa}
                    className={styles.item}
                    style={{ backgroundColor: companyColors[company.id_empresa] }}
                    >
                    <input
                        type="checkbox"
                        checked={selectedCompanies.includes(company.id_empresa)}
                        onChange={() => handleCompanySelect(company.id_empresa)}
                    />
                    <label>{company.nombre}</label>
                    </div>
                ))
                ) : (
                <p className={styles.message}>No hay empresas disponibles.</p>
                )}
            </div>

            {/* Lista de Equipos */}
            <div className={styles.column}>
                <h2>Equipos</h2>
                {selectedCompanies.length > 0 ? (
                selectedCompanies.flatMap((companyId) =>
                    companies
                    .find((company) => company.id_empresa === companyId)
                    ?.equipos.map((team) => (
                        <div
                        key={team.id_equipo}
                        className={styles.item}
                        style={{ backgroundColor: companyColors[companyId] }}
                        >
                        <input
                            type="checkbox"
                            checked={selectedTeams.includes(team.id_equipo)}
                            onChange={() => handleTeamSelect(team.id_equipo)}
                        />
                        <label>{team.nombre}</label>
                        </div>
                    ))
                )
                ) : (
                <p className={styles.message}>Selecciona una empresa para ver sus equipos.</p>
                )}
            </div>
            {/* Lista de Encuestas */}
            <div className={styles.column}>
                <h2>Encuestas</h2>
                {selectedTeams.length > 0 ? (
                selectedTeams.flatMap((teamId) =>
                    companies
                    .flatMap((company) => company.equipos)
                    .find((team) => team.id_equipo === teamId)
                    ?.encuestas.map((survey) => (
                        <div
                        key={survey.id_encuesta}
                        className={styles.item}
                        style={{
                            backgroundColor: companyColors[
                            companies.find((company) =>
                                company.equipos.some((team) => team.id_equipo === teamId)
                            )?.id_empresa
                            ],
                        }}
                        >
                        <input
                            type="checkbox"
                            checked={selectedSurveys.includes(survey.id_encuesta)}
                            onChange={() => handleSurveySelect(survey.id_encuesta)}
                        />
                        <label>{`${survey.fecha_inicio} - ${survey.fecha_fin}`}</label>
                        </div>
                    ))
                )
                ) : (
                <p className={styles.message}>Selecciona un equipo para ver sus encuestas.</p>
                )}
            </div>
            </div>
            <button className={styles.downloadButton} onClick={handleExport}>
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48">
                    <path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"/>
                    <path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"/>
                    <path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z"/><path fill="#17472a" d="M14 24.005H29V33.055H14z"/><g>
                    <path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"/>
                    <path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"/>
                    <path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z"/><path fill="#129652" d="M29 24.005H44V33.055H29z"/></g>
                    <path fill="#0c7238" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"/>
                    <path fill="#fff" d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"/>
                </svg>
                    {selectedCompanies.length > 0 ? t("export") : t("exportAll")}
            </button>
        </div>
        </div>
    );
}  

export default Database;

// RadarChartComponent.js
import React from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

function RadarChartComponent({ dashboardData }) {
  const { t } = useTranslation();

  const labels = [t("Goals"), t("Roles"), t("Processes"), t("Interactions")];

  const percentilizar = (value) => {
    if (value === null || value === undefined) return 0;
    return (value / 4) * 100;
  };
  const { mediaEquipo, mediaEquipoEmpresa, mediaEquipoGlobal } = dashboardData;

  const radarDatasets = [
    {
      label: t("personalAverage"),
      data: [
        percentilizar(dashboardData.mediaPersonal?.Goals),
        percentilizar(dashboardData.mediaPersonal?.Roles),
        percentilizar(dashboardData.mediaPersonal?.Processes),
        percentilizar(dashboardData.mediaPersonal?.Interactions),
      ],
      backgroundColor: "rgba(76, 135, 178, 1)",
      borderColor: "#4C87B2",
      pointBackgroundColor: "#4C87B2",
      borderWidth: 2,
    },
    {
      label: t("teamAverage"),
      data: [
        percentilizar(mediaEquipo.Goals),
        percentilizar(mediaEquipo.Roles),
        percentilizar(mediaEquipo.Processes),
        percentilizar(mediaEquipo.Interactions),
      ],
      backgroundColor:"#48BAA5",
      borderColor: "#48BAA5",
      pointBackgroundColor: "#48BAA5",
      barThickness: 30,
      borderRadius: 5,
      borderWidth: 2,
    },
    {
      label: t("companyAverage"),
      data: [
        percentilizar(mediaEquipoEmpresa.Goals),
        percentilizar(mediaEquipoEmpresa.Roles),
        percentilizar(mediaEquipoEmpresa.Processes),
        percentilizar(mediaEquipoEmpresa.Interactions),
      ],
      backgroundColor: "#595959",
      borderColor: "#595959",
      pointBackgroundColor: "#595959",
      borderWidth: 2,
      barThickness: 30,
      borderRadius: 5,
    },
    {
      label: t("globalAverage"),
      data: [
        percentilizar(mediaEquipoGlobal.Goals),
        percentilizar(mediaEquipoGlobal.Roles),
        percentilizar(mediaEquipoGlobal.Processes),
        percentilizar(mediaEquipoGlobal.Interactions),
      ],
      backgroundColor: "#e3147f",
      borderWidth: 2,
      borderColor: "#e3147f",
      pointBackgroundColor: "#e3147f",
      barThickness: 30,
      borderRadius: 5,
    }  
  ];

  const radarOptions = {
    maintainAspectRatio: false,
    scales: {
      r: {
        beginAtZero: true,
        max: 100,
        ticks: { stepSize: 20, font: { size: 14, family: "Inter, sans-serif" } },
      },
    },
    plugins: {
      legend: {
        position: "right",
        labels: {
          font: { size: 16, family: "Inter, sans-serif" },
          color: "#595959",
        },
        onHover: (event) => {
          event.native.target.style.cursor = "pointer";
        },
      },
    },
  };
  

  return (
    <Radar
      data={{ labels, datasets: radarDatasets }}
      options={radarOptions}
    />
  );
}

export default RadarChartComponent;

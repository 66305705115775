import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import style from "../../styles/gestionEncuesta.module.css";
import { useTranslation } from "react-i18next";

const GestionEncuesta = ({ onClose, onDelete, onAplicar, surveyGroups, sendNow }) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(Object.keys(surveyGroups)[0]);
    const [openTabs, setOpenTabs] = useState(Object.keys(surveyGroups));
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    const [selectedStartDate, setSelectedStartDate] = useState(yesterday);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [selectedPredefined, setSelectedPredefined] = useState(null);
    const [reminderDate, setReminderDate] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [contenidoCorreo, setContenidoCorreo] = useState('');
    const [isHolding, setIsHolding] = useState(false);
    const [holdTime, setHoldTime] = useState(0);
    const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false); // Estado para el popup de confirmación
    const holdDuration = 5000;
    const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useState(false);


    useEffect(() => {
        if (isHolding) {
            const interval = setInterval(() => {
                setHoldTime(prev => {
                    if (prev >= holdDuration) {
                        clearInterval(interval);
                        if (!isConfirmPopupOpen) {  // Asegúrate de que el popup solo se abra una vez
                            setIsConfirmPopupOpen(true); // Mostrar el popup de confirmación
                        }
                        return holdDuration; // Detener el incremento del tiempo
                    }
                    return prev + 100; // Aumentar el tiempo cada 100ms
                });
            }, 100);
            return () => clearInterval(interval);
        } else {
            setHoldTime(0); // Reiniciar cuando se deja de presionar
        }
    }, [isHolding, holdDuration, isConfirmPopupOpen]); // Añadimos isConfirmPopupOpen a las dependencias
    

    const handleSendNowPress = () => {
        setIsHolding(true);
    };

    const handleSendNowRelease = () => {
        setIsHolding(false);
    };

    // Confirmación del popup
    const handleConfirmSend = () => {
        setIsConfirmPopupOpen(false); // Cerrar popup de confirmación
        sendNow(selectedTab); // Ejecutar la función del padre
        setOpenTabs(prevOpenTabs => {
            const updatedTabs = prevOpenTabs.filter(tab => tab !== selectedTab);
            if (updatedTabs.length > 0) {
                setSelectedTab(updatedTabs[0]);
            } else {
                onClose();
            }
            return updatedTabs;
        });
    };

    // Cancelar la acción del popup
    const handleCancelSend = () => {
        setIsConfirmPopupOpen(false); // Cerrar popup sin hacer nada
    };

    // Calcular el ancho de la barra de progreso basándonos en el tiempo mantenido
    const progressWidth = (holdTime / holdDuration) * 100;
    
    useEffect(() => {
        const selectedSurvey = surveyGroups[selectedTab];
        if (selectedSurvey && selectedSurvey.encuesta.fechas) {
            const [start, end] = selectedSurvey.encuesta.fechas.split(' - ');
            setSelectedStartDate(new Date(start + "T00:00:00")); // Ajuste para garantizar la hora local
            setSelectedEndDate(new Date(end + "T00:00:00")); // Ajuste para garantizar la hora local
        }
        if (selectedSurvey && selectedSurvey.encuesta.recordatorio) {
            setReminderDate(new Date(selectedSurvey.encuesta.recordatorio));
        } else {
            setReminderDate(null);
        }
    
        if (selectedSurvey && selectedSurvey.encuesta.contenidoCorreo) {
            setContenidoCorreo(selectedSurvey.encuesta.contenidoCorreo);
        } else {
            setContenidoCorreo(`Queremos darte la bienvenida a SystemicSurvey, una herramienta diseñada para medir el bienestar de los empleados y mejorar el entorno de trabajo. Te invitamos a completar la encuesta a continuación para compartir tu experiencia y ayudarnos a identificar oportunidades de mejora. Tu opinión es fundamental para construir un ambiente laboral más saludable y productivo.`);
        }
    }, [selectedTab, surveyGroups]);
    
    
    
    function handleReminderDateChange(event) {
        setReminderDate(event.target.value ? new Date(event.target.value) : null);
    }
    

    function handleApply(surveyId) {
        const selectedSurvey = surveyGroups[surveyId];
    
        onAplicar(selectedStartDate, selectedEndDate, reminderDate, surveyId, selectedSurvey.equipos, contenidoCorreo);
    
        setOpenTabs(prevOpenTabs => {
            const updatedTabs = prevOpenTabs.filter(tab => tab !== surveyId);
            if (updatedTabs.length > 0) {
                setSelectedTab(updatedTabs[0]);
            } else {
                onClose();
            }
            return updatedTabs;
        });
    }
    
    function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1).getDay();
    }

    function getMonthName(monthNumber) {
        const monthNames = [
            t("january"),
            t("february"),
            t("march"),
            t("april"),
            t("may"),
            t("june"),
            t("july"),
            t("august"),
            t("september"),
            t("october"),
            t("november"),
            t("december"),
        ];
        return monthNames[monthNumber];
    }

    function handleDayClick(day) {
        const date = new Date(currentYear, currentMonth, day);
        date.setHours(0, 0, 0, 0); // Aseguramos que esté en la hora local
    
        if (
            (!selectedStartDate && !selectedEndDate) ||
            (selectedStartDate && selectedEndDate)
        ) {
            setSelectedStartDate(date);
            setSelectedEndDate(null);
            setSelectedPredefined(null);
        } else if (selectedStartDate && !selectedEndDate) {
            if (date < selectedStartDate) {
                setSelectedEndDate(selectedStartDate);
                setSelectedStartDate(date);
            } else {
                setSelectedEndDate(date);
            }
            setSelectedPredefined(null);
        }
    }
    

    function handleNextMonth() {
        const newDate = new Date(currentYear, currentMonth + 1);
        setCurrentMonth(newDate.getMonth());
        setCurrentYear(newDate.getFullYear());
    }

    function handlePreviousMonth() {
        const newDate = new Date(currentYear, currentMonth - 1);
        setCurrentMonth(newDate.getMonth());
        setCurrentYear(newDate.getFullYear());
    }

    const handleClickOutside = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };



    function renderCalendar() {
        const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
        const firstDayOfMonth = getFirstDayOfMonth(currentYear, currentMonth);
        const paddingDays = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
        const days = [];
        
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Establecer la hora en 0 para comparaciones
    
        for (let i = 1; i <= paddingDays + daysInMonth; i++) {
            const day = i - paddingDays;
            const date = new Date(currentYear, currentMonth, day);
            date.setHours(0, 0, 0, 0);
            
            const isToday = date.getTime() === today.getTime(); // Comparar con hoy
            const isSelectedDay =
                (selectedStartDate && date.getTime() === selectedStartDate.getTime()) ||
                (selectedEndDate && date.getTime() === selectedEndDate.getTime());
            const isInRange =
                selectedStartDate && selectedEndDate
                    ? date > selectedStartDate && date < selectedEndDate
                    : false;
            const isStartRange =
                selectedStartDate && date.getTime() === selectedStartDate.getTime();
            const isEndRange =
                selectedEndDate && date.getTime() === selectedEndDate.getTime();
                
            days.push(
                <div
                    key={i}
                    className={` ${style.calendarDay} ${isToday ? style.today : ""} ${isSelectedDay ? style.selectedDay : ""} ${isInRange ? style.inRangeDay : ""} ${isStartRange ? style.startRangeDay : ""} ${isEndRange ? style.endRangeDay : ""} ${day <= 0 ? style.paddingDay : ""}`}
                    onClick={day > 0 ? () => handleDayClick(day) : undefined}
                >
                    {day > 0 ? day : ""}
                </div>,
            );
        }
    
        return <div className={style.days}>{days}</div>;
    }
    

    const handlePredefinedClick = (weeks) => {
        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0); // Reset time to midnight
        const endDate = new Date(startDate); // Clone startDate to endDate
        endDate.setDate(startDate.getDate() + weeks * 7); // Add weeks
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
        setSelectedPredefined(weeks);
    };
    

    const handleEmailContentChange = (event) => {
        setContenidoCorreo(event.target.value);
    };

    const handleDeleteEncuesta = () => {
        onDelete(selectedTab);
        setOpenTabs(prevOpenTabs => {
            const updatedTabs = prevOpenTabs.filter(tab => tab !== selectedTab);
            if (updatedTabs.length > 0) {
                setSelectedTab(updatedTabs[0]);
            } else {
                onClose();
            }
            return updatedTabs;
        });
    };

    return ReactDOM.createPortal(
        <div className={style.popupOverlay} onClick={handleClickOutside}>
            <div className={style.popupContent}>
                <div className={style.tabs}>
                    {openTabs.map((surveyId) => (
                        <div 
                            key={surveyId}
                            className={`${style.tab} ${selectedTab === surveyId ? style.activeTab : ""}`}
                            onClick={() => setSelectedTab(surveyId)}
                        >
                            {surveyId === 'no-encuesta' ? t("newSurvey") : `${t("survey")} ${new Date(surveyGroups[surveyId].encuesta.fechas.split(' - ')[0]).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit' })} - ${new Date(surveyGroups[surveyId].encuesta.fechas.split(' - ')[1]).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit' })}`}
                        </div>
                    ))}
                </div>
                {openTabs.map((surveyId) => (
                    selectedTab === surveyId && (
                        <div key={surveyId} className={style.tabContent}>
                            <div className={style.popupGrid}>
                                <div className={style.calendarContainer}>
                                    <div className={style.calendario}>
                                        <p className={style.fechaño}>{getMonthName(currentMonth)}<br></br>{currentYear}</p>
                                        <div className={style.datepicker}>
                                            <button onClick={handlePreviousMonth}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 600 960" width="24" > <path d="M400-93.847 13.847-480 400-866.153l56.768 56.768L127.384-480l329.384 329.385L400-93.847Z" /> </svg>
                                            </button>
                                            <button onClick={handleNextMonth}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 800 960" width="24"><path d="m320.615-93.847-56.768-56.768L593.232-480 263.847-809.385l56.768-56.768L706.768-480 320.615-93.847Z" /> </svg>
                                            </button>
                                        </div>
                                        <div className={style.dayLabels}>
                                            <div>L</div>
                                            <div>M</div>
                                            <div>X</div>
                                            <div>J</div>
                                            <div>V</div>
                                            <div>S</div>
                                            <div>D</div>
                                        </div>
                                        {renderCalendar()}
                                    </div>
                                </div>
                                <div className={style.gestionFechasEquipos}>
                                    <div className={style.fechasiniciofin}>
                                        <div className={style.fechainicio}>
                                            <p>{t("startDate")}</p>
                                            <p>{selectedStartDate.toLocaleDateString()}</p>
                                        </div>
                                        <div className={style.fechafin}>
                                            <p>{t("endDate")}</p>
                                            <p>{selectedEndDate ? selectedEndDate.toLocaleDateString() : selectedStartDate.toLocaleDateString()}</p>
                                        </div>

                                        <div className={style.fechafin}>
                                            <p>{t("reminderDate")}</p>
                                            <div className={style.dateInputWrapper}>
                                                <input 
                                                    type="date" 
                                                    className={style.dateInput}
                                                    value={reminderDate ? reminderDate.toISOString().split('T')[0] : ""} 
                                                    onChange={handleReminderDateChange}
                                                />
                                            </div>
                                        </div>

                                        
                                        

                                    </div>
                                    <div className={style.fechasPredefinidas}>
                                        <div className={`${style.predefinida} ${selectedPredefined === 1 ? style.active : ""}`} onClick={() => handlePredefinedClick(1)}> <p>{t("1week")}</p>
                                        </div>
                                        <div className={`${style.predefinida} ${selectedPredefined === 2 ? style.active : ""}`} onClick={() => handlePredefinedClick(2)}><p>{t("2week")}</p></div>
                                        <div className={`${style.predefinida} ${selectedPredefined === 3 ? style.active : ""}`} onClick={() => handlePredefinedClick(3)}> <p>{t("3week")}</p></div>
                                    </div>
                                    {/* Texto informativo de que 24 se enviará un recordatorio */}
                                    <div className={style.recordatorio}>
                                        <p>{t("reminderText")}</p>
                                    </div>
                                    {/* Botón para modificar correo */}
                                    <div className={style.divModificarCorreo}>
                                        <button 
                                            className={style.modifyMailwithIcon} 
                                            onClick={() => setIsSidebarOpen(true)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1.2000000000000002 -2.4000000000000004 26.4 32.4"><path d="M22,8v4c0,.55-.45,1-1,1s-1-.45-1-1v-4c0-.55-.45-1-1-1H5c-.55,0-1,.45-1,1v8c0,.55,.45,1,1,1h6c.55,0,1,.45,1,1s-.45,1-1,1H5c-1.65,0-3-1.35-3-3V8c0-1.65,1.35-3,3-3h14c1.65,0,3,1.35,3,3Zm-4.07,1.61c-.21-.51-.79-.76-1.3-.56l-4.64,1.86-4.63-1.85c-.51-.21-1.1,.04-1.3,.56-.21,.51,.04,1.09,.56,1.3l5,2c.12,.05,.25,.07,.37,.07s.25-.02,.37-.07l5.01-2.01c.51-.21,.76-.79,.56-1.3Zm1.55,5c-.39-.39-1.02-.39-1.41,0l-3.54,3.54c-.11,.11-.19,.24-.24,.39l-.71,2.12c-.12,.36-.03,.76,.24,1.02,.19,.19,.45,.29,.71,.29,.11,0,.21-.02,.32-.05l2.12-.71c.15-.05,.28-.13,.39-.24l3.54-3.54c.39-.39,.39-1.02,0-1.41l-1.41-1.41Z"/></svg>
                                            {t('modifyEmail')}
                                        </button>
                                    </div>
                                    <div className={style.equiposSeleccionados}>
                                        <ul>
                                            {surveyGroups[surveyId].equipos.map((team) => (
                                                <li key={team.id_equipo}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m424-312 282-282-56-56-226 226-114-114-56 56 170 170ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>
                                                    {team.nombre}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className={style.divButtonsPopup}>
                                    {/* Mostrar el botón solo si la encuesta estado es programada */}
                                    {surveyGroups[surveyId].encuesta.estado === 'programada' && (
                                        <button
                                            className={style.buttonSendNow}
                                            onMouseDown={handleSendNowPress}  // Detecta cuando se presiona
                                            onMouseUp={handleSendNowRelease}  // Detecta cuando se suelta
                                            onMouseLeave={handleSendNowRelease} // Detecta si el mouse sale del botón
                                        >
                                            <div className={style.progressBar} style={{ width: `${progressWidth}%` }} />
                                            <span className={style.buttonText}>{t("sendNow")}</span>
                                        </button>
                                    )}
                                        <button className={style.buttonDelete} onClick={() => setIsConfirmDeletePopupOpen(true)}>{t("delete")}</button>
                                        <button className={style.buttonCancel} onClick={() => onClose()}>{t("cancel")}</button>
                                        <button className={style.buttonApply} onClick={() => handleApply(surveyId)}>{t("apply")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ))}
            </div>

            {isSidebarOpen && (
                <div className={style.popupOverlay}>
                    <div className={style.sidebarMail}>
                        <div className={style.sidebarHeader}>
                            <h2>{t('modifyEmail')}</h2>
                            <button className={style.closeButtonMail} onClick={() => setIsSidebarOpen(false)}>{t('close')}</button>
                        </div>
                        <div className={style.sidebarContent}>
                            <div style={{ fontFamily: 'Arial, sans-serif', color: '#333', backgroundColor: '#f4f4f4', padding: '1rem 2rem' }}>
                                <table width="100%" style={{ backgroundColor: '#f4f4f4' }}>
                                    <tr>
                                        <td>
                                            <table align="center" width="100%" style={{ backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <img src="img/logo8.png" alt="Logo 8inCompany" width="150" style={{ maxWidth: '150px', margin: '20px' }} />
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td style={{ textAlign: 'center' }}>
                                                        <h1 style={{ color: '#0B1D51' }}>{t("programedSurvey")}</h1>   
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td className={style.contenidoModificable}  style={{ color: '#333' }}>
                                                        <textarea 
                                                            className={style.inputEditable} 
                                                            value={contenidoCorreo}
                                                            onChange={handleEmailContentChange}
                                                            style={{ width: '95%', height: '100px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                                                        />
                                                        <p style={{ textAlign: 'center' }}>
                                                            <div href="#" style={{ backgroundColor: '#e3147f', color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold', padding: '10px 20px', borderRadius: '5px', display: 'inline-block', margin: '1rem 0', opacity: '0.3' }}>
                                                                {t("completeSurvey")}
                                                            </div>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={style.contenidoModificable} style={{ color: '#333', padding: '1rem' }}>
                                                        <p>{t("thanksForParticipating")}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={style.contenidoModificable2}  style={{ textAlign: 'center', fontSize: '0.8em', color: '#aaa' }}>
                                                        <p>8incompany</p>
                                                        <p>info@8incompany.com</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={style.contenidoModificable3}  style={{ textAlign: 'center', fontSize: '0.8em', color: '#aaa' }}>
                                                        <p>Powered by</p>
                                                        <img className={style.logoFeelinback} src="/img/logo_largo.png" alt="" />
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <button className={style.saveButton} onClick={() => setIsSidebarOpen(false)}>{t("save")}</button>
                        </div>
                    </div>
                </div>
            )}
            {isConfirmPopupOpen && (
                <div className={style.confirmOverlay}>
                    <div className={style.confirmPopup}>
                        <h2>{t("sendNow?")}</h2>
                        <p>{t("confirmSendNowEmail")}</p>
                        <div className={style.confirmButtons}>
                            <button onClick={handleConfirmSend} className={style.confirmButton}>{t("confirm")}</button>
                            <button onClick={handleCancelSend} className={style.cancelButton}>{t("cancel")}</button>
                        </div>
                    </div>
                </div>
            )}

                {isConfirmDeletePopupOpen && (
                    <div className={style.confirmOverlay}>
                        <div className={style.confirmPopup}>
                            <h2>{t("deleteSurvey?")}</h2>
                            <p>{t("confirmDeleteSurvey")}</p>
                            <div className={style.confirmButtons}>
                                <button 
                                    onClick={() => {
                                        handleDeleteEncuesta(); // Ejecutar la eliminación
                                        setIsConfirmDeletePopupOpen(false); // Cerrar el popup
                                    }} 
                                    className={style.confirmButton}
                                >
                                    {t("confirm")}
                                </button>
                                <button 
                                    onClick={() => setIsConfirmDeletePopupOpen(false)} 
                                    className={style.cancelButton}
                                >
                                    {t("cancel")}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

        </div>,
        document.body
    );
};

export default GestionEncuesta;